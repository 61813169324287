import { Component, Input } from '@angular/core';
import { ValidationConstants } from '@shared/constants/validation-constants';
import { DateTimeFilter } from '@shared/resources/analysis/datetimefilter/date-time-filter';
import { DateTimeFilterEntry } from '@shared/resources/analysis/datetimefilter/date-time-filter-entry';

@Component({
  selector: 'app-date-time-filter',
  templateUrl: './date-time-filter.component.html',
  styleUrl: './date-time-filter.component.scss'
})

export class DateTimeFilterComponent {

  @Input({ required: true }) public dateTimeFilter: DateTimeFilter;

  public onAddDateAndTimeFilterClick() {
    const dateTimeFilter: DateTimeFilterEntry = {
      startDate: new Date(ValidationConstants.FIRST_JOURNEY_DATE),
      endDate: new Date(),
      startTime: 0,
      endTime: 23,
      daysOfWeek: [1, 2, 3, 4, 5]
    };
    this.dateTimeFilter.entries.push(dateTimeFilter);
  }

  public onDateTimeFilterEntryToBeDeleteEvent(dateTimeFilterEntryToBeDeleted: DateTimeFilterEntry) {
    const index = this.dateTimeFilter.entries.findIndex(entry => entry === dateTimeFilterEntryToBeDeleted);
    this.dateTimeFilter.entries.splice(index, 1);
  }
}
