import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Bounds } from '@shared/constants/bounds';
import { TransportFilter } from '@shared/resources/analysis/transportfilter/transport-filter';
import { TransportModeType } from '@shared/resources/analysis/transportfilter/transport-mode-type';

@Component({
  selector: 'app-transport-filter',
  templateUrl: './transport-filter.component.html',
  styleUrl: './transport-filter.component.scss'
})
export class TransportFilterComponent {

  public readonly TRANSPORT_MODES: TransportModeType[] = ['BIKE', 'BOAT', 'BUS', 'CAR', 'FERRY', 'FOOT',
    'LIGHTRAIL', 'METRO', 'TRAIN', 'TRAM'];

  public readonly MIN_TRAVELTIME_MINUTES = Bounds.MIN_TRAVELTIME_MINUTES;
  public readonly MAX_TRAVELTIME_MINUTES = Bounds.MAX_TRAVELTIME_MINUTES;
  public readonly MIN_AVG_SPEED_KPH = Bounds.MIN_AVG_SPEED_KPH;
  public readonly MAX_AVG_SPEED_KPH = Bounds.MAX_AVG_SPEED_KPH;
  public readonly MIN_DISTANCE_KM = Bounds.MIN_DISTANCE_KM;
  public readonly MAX_DISTANCE_KM = Bounds.MAX_DISTANCE_KM;

  public sortedTransportModes: string[];

  public formatTravelTimeValue = (value: number) => value === this.MAX_TRAVELTIME_MINUTES ? '∞' : `${value}`;
  public formatAvgSpeedValue = (value: number) => value === this.MAX_AVG_SPEED_KPH ? '∞' : `${value}`;
  public formatDistanceValue = (value: number) => value === this.MAX_DISTANCE_KM ? '∞' : `${value}`;

  @Input({ required: true }) public transportFilter: TransportFilter;

  constructor(private translateService: TranslateService) {
    this.translateService
      .getTranslation(this.translateService.currentLang)
      .subscribe(() => {
        this.sortedTransportModes = this.getSortedTranslations();
      });
  }

  public getSliderLabel(valueMin: number, valueMax: number, boundsMin: number, boundsMax: number) {
    if (valueMin === boundsMin && valueMax === boundsMax) {
      return '';
    } else if (valueMax === boundsMax) {
      return `> ${valueMin} `;
    } else {
      return `${valueMin} — ${valueMax} `;
    }
  }

  private getSortedTranslations() {
    return this.TRANSPORT_MODES
      .map(mode => ({
        translated: this.translateService.instant('TRANSPORT_FILTER.TYPES.' + mode),
        original: mode
      }))
      .sort((a, b) => a.translated.localeCompare(b.translated))
      .map(mode => mode.original);
  }
}
