import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationConstants } from '@shared/constants/validation-constants';
import { DateTimeFilterEntry } from '@shared/resources/analysis/datetimefilter/date-time-filter-entry';

@Component({
  selector: 'app-date-time-filter-entry',
  templateUrl: './date-time-filter-entry.component.html',
  styleUrl: './date-time-filter-entry.component.scss'
})
export class DateTimeFilterEntryComponent {

  @Input({ required: true }) public dateTimeFilterEntry: DateTimeFilterEntry;

  @Output() public delete: EventEmitter<DateTimeFilterEntry> = new EventEmitter();

  public readonly minimumDate = ValidationConstants.FIRST_JOURNEY_DATE;
  public readonly maximumDate = new Date();

  public formatLabel(value: number): string {
    return `${value}`;
  }

  public onDeleteClick(dateTimeFilterEntryToDelete: DateTimeFilterEntry) {
    this.delete.emit(dateTimeFilterEntryToDelete);
  }

}
