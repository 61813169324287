<app-analysis-counts [analysis]="analysis" (crossFilterValid)="crossFilterValid = $event"></app-analysis-counts>

<app-cross-filter-chips></app-cross-filter-chips>

<mat-divider></mat-divider>

@if (analysis) {
@if (!crossFilterValid) {
<div class="invalid-analysis">
  <mat-icon class="material-icons-outlined">report_problem</mat-icon>
  {{ 'ANALYSIS_INPUT.ERRORS.INVALID_PERSON_COUNT' | translate }}
</div>
<button class="reset-filter-button" mat-stroked-button (click)="revertCrossFilter()">
  {{ 'ANALYSIS_OVERVIEW.REVERT_LAST_CROSS_FILTER' | translate }}
</button>
} @else {
<div class="charts-container" cdkDropList id="ChartBarDropList" (cdkDropListDropped)="onChartDropped($event)">
  @for (chart of chartsOrder; track chart) {
  <div cdkDrag cdkDragBoundary="#ChartBarDropList" cdkDragLockAxis="y">
    <div class="chart-bar-item-drag-placeholder" *cdkDragPlaceholder></div>
    <div class="mat-typography mat-elevation-z3 chart-bar-item-drag-preview" *cdkDragPreview>
      {{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chart | translate}}
    </div>

    <mat-expansion-panel [(expanded)]="chartsExpanded[chart]" (expandedChange)="onChartExpandedChange()"
      class="diagram-panel">

      <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="48px">
        <mat-panel-title>
          <div class="drag-handle" cdkDragHandle>
            <mat-icon>drag_indicator</mat-icon>
          </div>
          @if (chart === 'TREND') {
          <div class="item-name">{{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chart + '.' + activeToggle | translate}}
          </div>
          } @else {
          <div class="item-name">{{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chart | translate}}</div>
          }
          <div class="icons">
            <button mat-icon-button [matMenuTriggerFor]="exportMenu" (click)="$event.stopPropagation();">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
          <mat-menu #exportMenu="matMenu">
            <button mat-menu-item (click)="exportChart(chart)">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_AS_IMAGE' | translate}}</button>
            <button mat-menu-item (click)="copyChartToClipboard(chart)">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.COPY_TO_CLIPBOARD' | translate}}</button>
            <button mat-menu-item (click)="exportChartToCsv(chart)">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_AS_CSV' | translate}}</button>
            @if (chart === 'ORIGINS_AND_DESTINATIONS') {
            <button mat-menu-item (click)="exportOdMatrix(chart)" [disabled]="exportInProgress">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_OD_MATRIX' | translate}}</button>
            }
          </mat-menu>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>
        @switch (chart) {
        @case('KPI') {
        <app-kpi-overview [analysisId]="analysis.analysisId"></app-kpi-overview>
        }
        @case ('MOVEMENTS') {
        <app-modal-split-chart [analysisId]="analysis.analysisId"></app-modal-split-chart>
        }
        @case ('TREND') {
        <app-journeys-count-per-date-graph [analysisId]="analysis.analysisId"></app-journeys-count-per-date-graph>
        }
        @case ('TREND_GROUPED_MODES') {
        <app-trends-by-modegroup [analysisId]="analysis.analysisId"></app-trends-by-modegroup>
        }
        @case ('ORIGINS_AND_DESTINATIONS') {
        <app-od-flows-chart [analysisId]="analysis.analysisId"></app-od-flows-chart>
        }
        @case('PANELISTS_HOUSEHOLDS'){
        <app-household-chart [analysisId]="analysis.analysisId"></app-household-chart>
        }
        @case('PANELISTS_URBANISATION'){
        <app-urbanisation-chart [analysisId]="analysis.analysisId"></app-urbanisation-chart>
        }
        @case('OBJECTIVES'){
        <app-objectives-chart [analysisId]="analysis.analysisId"></app-objectives-chart>
        }
        @case('MODE_CHAIN'){
        <app-mode-transitions-chart [analysisId]="analysis.analysisId"></app-mode-transitions-chart>
        }
        }
      </ng-container>
    </mat-expansion-panel>
  </div>
  }
</div>
}
}
