import { Routes } from '@angular/router';
import { NoLicenseComponent } from './auth/no-license/no-license.component';
import { NvpAnalysisCompareComponent } from './components/nvp-analysis-compare/nvp-analysis-compare.component';
import { NvpAnalysisInputComponent } from './components/nvp-analysis-input/nvp-analysis-input.component';
import { NvpAnalysisListComponent } from './components/nvp-analysis-list/nvp-analysis-list.component';
import { NvpAnalysisMapComponent } from './components/nvp-analysis-map/nvp-analysis-map.component';
import { authGuardFn } from './guards/auth.guards';

export const appRoutes: Routes = [
  {
    path: 'analysis-list',
    component: NvpAnalysisListComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'analysis-config',
    component: NvpAnalysisInputComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'analysis-config/:id',
    component: NvpAnalysisInputComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'analysis/:id',
    component: NvpAnalysisMapComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'analysis-compare',
    component: NvpAnalysisCompareComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'authorization/no-license',
    component: NoLicenseComponent,
  },
  {
    path: '**',
    redirectTo: 'analysis-list'
  }
];
