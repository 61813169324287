<div class="geo-filter-entry" [ngClass]="geoFilterEntry.type.toLowerCase()">
  <div class="small">{{'GEO_FILTER.LABEL_FILTER_1' | translate}}</div>
  <mat-form-field>
    <mat-select data-cy="geo-filter-type" [(ngModel)]="geoFilterEntry.type" panelWidth=""
      (selectionChange)="onFilterTypeChanged()">
      @for(type of types; track $index) {
      <mat-option data-cy="geo-filter-type-value" [value]="type">{{'GEO_FILTER.' + type | translate}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="small">{{'GEO_FILTER.LABEL_FILTER_2' | translate}}</div>
  <mat-form-field>
    <mat-select [(ngModel)]="geoFilterEntry.geoType" panelWidth="" (selectionChange)="onGeoTypeChanged()"
      [disabled]="locked">
      @for(geoType of geoTypes; track $index) {
      <mat-option [value]="geoType">{{'GEO_FILTER.' + geoType | translate}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (geoFilterEntry.geoType === 'MUNICIPALITY') {
  <mat-form-field class="stretch">
    <mat-select data-cy="area-filters" [(ngModel)]="geoFilterEntry.codes" multiple="true" panelWidth=""
      [placeholder]="'GEO_FILTER.SELECT_AREA_PLACEHOLDER' | translate">
      <mat-option>
        <ngx-mat-select-search data-cy="type-area" [placeholderLabel]="'ANALYSIS_INPUT.FILTER_TYPE_TEXT' | translate"
          [noEntriesFoundLabel]="'ANALYSIS_INPUT.FILTER_NOT_FOUND' | translate" ngModel
          (ngModelChange)="filterMunicipalities($event)"></ngx-mat-select-search>
      </mat-option>
      @for(municipality of filteredMunicipalities | async; track municipality.code) {
      <mat-option data-cy="municipality-filtered" [value]="municipality.code">{{municipality.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  } @else if(geoFilterEntry.geoType === 'CUSTOM_REGION'){
  <mat-form-field class="stretch">
    <mat-select data-cy="region-filters" [(ngModel)]="geoFilterEntry.customRegionIds" multiple="true" panelWidth=""
      [placeholder]="'GEO_FILTER.SELECT_AREA_PLACEHOLDER' | translate">
      <mat-option>
        <ngx-mat-select-search data-cy="type-area" [placeholderLabel]="'ANALYSIS_INPUT.FILTER_TYPE_TEXT' | translate"
          [noEntriesFoundLabel]="'ANALYSIS_INPUT.FILTER_NOT_FOUND' | translate" ngModel
          (ngModelChange)="filterCustomRegions($event)"></ngx-mat-select-search>
      </mat-option>
      @for(customRegion of filteredCustomRegions | async; track customRegion.id) {
      <mat-option data-cy="custom-region-filtered" [value]="customRegion.id">{{customRegion.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  }

  @else {
  <div class="stretch"></div>
  }
  <div class="small">
    <button mat-icon-button [disabled]="true">
      <mat-icon>tune</mat-icon>
    </button>
    <button mat-icon-button (click)="onDeleteClick(geoFilterEntry);" data-cy="delete-geo-filter-entry">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
