import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GeoFilterEntry } from '@shared/resources/analysis/geofilter/geo-filter-entry';
import { CustomRegions } from '@shared/resources/geography/custom-regions';
import { Municipality } from '@shared/resources/geography/municipality';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-geo-filter-entry',
  templateUrl: './geo-filter-entry.component.html',
  styleUrl: './geo-filter-entry.component.scss',
})
export class GeoFilterEntryComponent {
  @Input({ required: true }) public geoFilterEntry: GeoFilterEntry;
  @Input() public locked: boolean;

  @Output() public filterTypeChanged: EventEmitter<GeoFilterEntry> = new EventEmitter();
  @Output() public geoTypeChanged: EventEmitter<GeoFilterEntry> = new EventEmitter();
  @Output() public delete: EventEmitter<GeoFilterEntry> = new EventEmitter();

  public readonly types: GeoFilterEntry['type'][] = ['ORIGIN', 'DESTINATION', 'PASS_THROUGH', 'INTERSECT'];

  public geoTypes: GeoFilterEntry['geoType'][] = ['MUNICIPALITY', 'POLYGON'];

  private _municipalities: Municipality[] = [];
  private _customRegions: CustomRegions[] = [];

  @Input({ required: true }) set municipalities(municipalities: Municipality[]) {
    this._municipalities = municipalities;
    this.filteredMunicipalities.next(municipalities);
  }

  get municipalities(): Municipality[] {
    return this._municipalities;
  }

  @Input({ required: true }) set customRegions(customRegions: CustomRegions[]) {
    this._customRegions = customRegions;
    this.filteredCustomRegions.next(customRegions);
    this.geoTypes = this.customRegions.length === 0
      ? ['MUNICIPALITY', 'POLYGON']
      : ['MUNICIPALITY', 'CUSTOM_REGION', 'POLYGON'];
  }

  get customRegions(): CustomRegions[] {
    return this._customRegions;
  }

  public filteredMunicipalities = new BehaviorSubject<Municipality[]>([]);
  public filteredCustomRegions = new BehaviorSubject<CustomRegions[]>([]);

  public filterMunicipalities(searchText: string) {
    if (!this.municipalities) {
      return;
    }
    if (!searchText) {
      this.filteredMunicipalities.next([...this.municipalities]);
      return;
    }

    this.filteredMunicipalities.next(
      this.municipalities.filter(
        (municipality) =>
          municipality.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          municipality.code.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }

  public filterCustomRegions(searchText: string) {
    if (!this.customRegions) {
      return;
    }
    if (!searchText) {
      this.filteredCustomRegions.next([...this.customRegions]);
      return;
    }

    this.filteredCustomRegions.next(
      this.customRegions.filter(
        (municipality) =>
          municipality.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          municipality.id.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }

  public onFilterTypeChanged() {
    this.filterTypeChanged.emit(this.geoFilterEntry);
  }

  public onGeoTypeChanged() {
    if (this.geoFilterEntry.geoType === 'POLYGON') {
      this.geoFilterEntry.geometry = {
        type: "Polygon",
        coordinates: []
      };
      delete (this.geoFilterEntry as any).codes;
      delete (this.geoFilterEntry as any).customRegionIds;
    } else if (this.geoFilterEntry.geoType !== 'CUSTOM_REGION') {
      this.geoFilterEntry.codes = [];
      delete (this.geoFilterEntry as any).geometry;
      delete (this.geoFilterEntry as any).customRegionIds;
    } else {
      this.geoFilterEntry.customRegionIds = [];
      delete (this.geoFilterEntry as any).codes;
      delete (this.geoFilterEntry as any).geometry;
    }

    this.geoTypeChanged.emit(this.geoFilterEntry);
  }

  public onDeleteClick(geoFilterEnrtyToDelete: GeoFilterEntry) {
    this.delete.emit(geoFilterEnrtyToDelete);
  }
}
