<div class="date-time-filters">
  <div class="add-filter">
    @for (dateTimeFilterEntry of dateTimeFilter.entries; track $index) {
    <app-date-time-filter-entry [dateTimeFilterEntry]="dateTimeFilterEntry"
      (delete)="onDateTimeFilterEntryToBeDeleteEvent($event)"></app-date-time-filter-entry>
    }
    <button mat-stroked-button data-cy="time-add-button" class="add-button" color="primary"
      (click)="onAddDateAndTimeFilterClick()">+ {{
      'DATE_TIME_FILTER.BUTTON_ADD_FILTER' |
      translate }}</button>
  </div>
</div>
