import { ElementRef, Injectable } from '@angular/core';
import * as echarts from 'echarts';
import html2canvas from 'html2canvas';
import { BehaviorSubject } from 'rxjs';
import { PanelType } from '../components/nvp-analysis-map/nvp-analysis-map.component';
import { CsvDataService } from './csv-data.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  readonly exportClicked: BehaviorSubject<PanelType | null> = this.getNewSubject();
  readonly copyClipboardClicked: BehaviorSubject<PanelType | null> = this.getNewSubject();
  readonly exportCsvClicked: BehaviorSubject<PanelType | null> = this.getNewSubject();
  readonly exportOdMatrixClicked: BehaviorSubject<PanelType | null> = this.getNewSubject();
  readonly exportOdMatrixInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private csvDataService: CsvDataService) { }

  public triggerExport(panel: PanelType) {
    this.exportClicked.next(panel);
  }

  public triggerCopyToClipboard(panel: PanelType) {
    this.copyClipboardClicked.next(panel);
  }

  public triggerExportCsv(panel: PanelType) {
    this.exportCsvClicked.next(panel);
  }

  public triggerExportOdMatrix(panel: PanelType) {
    this.exportOdMatrixInProgress.next(true);
    this.exportOdMatrixClicked.next(panel);
  }

  public completeExportOdMatrix() {
    this.exportOdMatrixInProgress.next(false);
  }

  public async exportChart(echart: echarts.ECharts) {
    if (echart === undefined) {
      return;
    }
    try {
      const base64 = await this.createChartImage(echart);
      if (!base64) {
        console.error('Failed to create chart image');
      }
      const link = document.createElement('a');
      link.href = base64;
      link.download = 'chart.png';
      link.click();
    } catch (e) {
      console.error('Error exporting chart', e);
    }
  }

  public async copyChartToClipboard(echart: echarts.ECharts) {
    if (echart === undefined) {
      return;
    }
    try {
      const base64 = await this.createChartImage(echart);
      if (!base64) {
        console.error('Failed to create chart image');
      }
      const blob = await fetch(base64).then(r => r.blob());
      await this.writeBlobToClipboard(blob);
    } catch (e) {
      console.error('Error copying chart to clipboard', e);
    }
  }

  public exportDataToCsv(fileName: string, data: object[]) {
    if (data === undefined) {
      return;
    }
    this.csvDataService.exportToCsv(fileName, data);
  }

  public async exportKpi(divToExport: ElementRef<any>) {
    document.body.style.cursor = 'wait';

    // Short delay to allow UI update before html2canvas freezes it
    await new Promise(resolve => setTimeout(resolve, 10));

    try {
      const canvas = await html2canvas(divToExport.nativeElement);
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'exported-image.png';
      link.click();
    } finally {
      document.body.style.cursor = '';
    }
  }

  public async exportKpiToClipboard(divToExport: ElementRef<any>) {
    document.body.style.cursor = 'wait';
    await new Promise(resolve => setTimeout(resolve, 10)); // Short delay to allow UI update

    try {
      const canvas = await html2canvas(divToExport.nativeElement);
      canvas.toBlob(blob => {
        if (blob) {
          this.writeBlobToClipboard(blob);
        } else {
          console.error('Failed to create blob from canvas');
        }
      });
    } finally {
      document.body.style.cursor = '';
    }
  }

  private async writeBlobToClipboard(blob: Blob) {
    const item = new ClipboardItem({ "image/png": blob });
    await navigator.clipboard.write([item]).catch(error => {
      console.error('Failed to copy image to clipboard: ', error);
    });
  }

  private createChartImage(echart: echarts.ECharts): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const base64 = echart.getDataURL({ type: 'png', pixelRatio: 2, backgroundColor: '#fff' });
        resolve(base64);
      } catch (error) {
        console.error('Error creating chart image', error);
        reject(error);
      }
    });
  }

  private getNewSubject(): BehaviorSubject<PanelType | null> {
    return new BehaviorSubject<PanelType | null>(null);
  }
}
